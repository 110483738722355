import { FC, useRef, useMemo, useEffect, useState, SyntheticEvent } from 'react'

import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'

import { shopStateAtom } from '@state/shop'
import { pageStateAtom } from '@state/page'
import { catalogShopProductEditStateAtom } from '@state/catalogShopProductEdit'

import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import FolderIcon from '@mui/icons-material/Folder';
import RestoreIcon from '@mui/icons-material/Restore';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
const Navigation: FC = (props:any) => {

  const [page, setPage] = useRecoilState(pageStateAtom)
  
  const setCatalogShopProductEdit = useSetRecoilState<boolean>(catalogShopProductEditStateAtom)

  const handleChange = (event: SyntheticEvent, newValue: string) => {

    if(newValue === 'catalog') setCatalogShopProductEdit(false);
    setPage(newValue);
  };

  return (
    <Box sx={{ width: '100%', position: 'fixed', bottom: 0, minHeight: '60px', height: 'env(safe-area-inset-bottom)', backgroundColor: 'rgb(255, 255, 255)',/* paddingBottom: '10px', *//* 'calc(5px + env(safe-area-inset-bottom))' */ }}>
      <BottomNavigation sx={{ width: '100%', height: '100%', alignItems: 'center', /* position: 'fixed', bottom: 0, paddingBottom: 'env(safe-area-inset-bottom)', */ }} showLabels value={page} onChange={handleChange}>
          <BottomNavigationAction
            label="Карта"
            value="map"
            icon={<LocationOnIcon />}
          />

          <BottomNavigationAction
            label="Каталог"
            value="catalog"
            icon={<ManageSearchIcon />}
          />

          <BottomNavigationAction
            label="Премиум"
            value="premiums"
            icon={<FavoriteIcon />}
          />
          <BottomNavigationAction
            label="Корзина"
            value="basket"
            icon={<ShoppingCartIcon />}
          />

          <BottomNavigationAction
            label="Аккаунт"
            value="account"
            icon={<AccountCircleIcon />}
          />
      </BottomNavigation>
    </Box>
  )
}

export default Navigation
