
import { FC, useEffect, useState, useRef, useMemo } from 'react'

import qs from 'qs'

import Flicking from "@egjs/react-flicking";

import { AutoPlay } from "@egjs/flicking-plugins";
import "@egjs/react-flicking/dist/flicking.css";

import { size } from 'lodash'

import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'

import { categoriesStateAtom } from '@state/categories'
import { categoryCatalogStateAtom } from '@state/categoryCatalog'

import { productStateAtom } from '@state/product'
import { productSortStateAtom } from '@state/productSort'

import { basketStateAtom } from '@state/basket'

import { userDataStateAtom } from '@state/userData'
import { catalogShopProductEditStateAtom } from '@state/catalogShopProductEdit'

import { productEditStateAtom } from '@state/productEdit'
import { selectCategoryForAddProductStateAtom } from '@state/selectCategoryForAddProduct'

import { pageStateAtom } from '@state/page'

import Grid from '@mui/material/Unstable_Grid2'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack'

import Button from '@mui/material/Button'

import TextField from '@mui/material/TextField'
import TuneIcon from '@mui/icons-material/Tune';

import CircularProgress from '@mui/material/CircularProgress';

import Typography from '@mui/material/Typography';

import { useForm, Controller } from 'react-hook-form'

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import CardMedia from '@mui/material/CardMedia';

import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';

import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

/* import SearchIcon from '@mui/icons-material/Search'; */
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import useMeasure from '@hooks/useMeasure';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import Draggable from '@components/Draggable';

import api from '@services/api';

const Schema = yup.object().shape({
  name: yup.string().required("Необходимо ввести ключ"),
});

const ProductItem: FC<any> = ({ product }) => {

  const setOpenProductDetails = useSetRecoilState<any>(productStateAtom);
  
  const [basket, setBasket] = useRecoilState<any[]>(basketStateAtom)

  const category = useRecoilValue<any>(categoryCatalogStateAtom);

  const userData = useRecoilValue<any>(userDataStateAtom);
  const catalogShopProductEdit = useRecoilValue<boolean>(catalogShopProductEditStateAtom);

  
  const setSelectCategoryForAddProduct = useSetRecoilState<any>(selectCategoryForAddProductStateAtom);
  const setProductEdit = useSetRecoilState<any>(productEditStateAtom);
  const setPage = useSetRecoilState<any>(pageStateAtom);

  useEffect(() => {
    console.log('catalogShopProductEdit')
    console.log(catalogShopProductEdit)
    
  }, [catalogShopProductEdit])

  const handleCheckedBasket = () => {

    for(const item of basket) {

      if(item?.id === product?.id) return true;
    }
    return false
  };
  
  const handleAddBasket = () => {

    setBasket([...basket, product]);
  };

  const handleRemoveBasket = () => {

    setBasket(basket.filter((item) => item?.id !== product?.id));
  };

  const firstImage = product?.files?.find((item: any) => item?.mime.includes("image"));

  return (
    <Grid xs={6}>
      <Box sx={{m: 0, mt: 2,mb: 2}}>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
              height="100%"
              width="100%"
              minHeight={280}

              onClick={()=>{
                 
                setOpenProductDetails(product)
              }}
            >
              {/* <Box
                sx={{ 
                  backgroundImage:`url(${product?.photo?.url})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: 280,
                  width: '100%',
                  borderRadius: 5,
                }}
              /> */}
              <Box
                sx={{ 
                  backgroundImage:`url(${firstImage?.url})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: 'center',
                  height: 280,
                  width: '100%',
                  borderRadius: 5,
                }}
              /> 
            </Box>

            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={1}
              sx={{
                m: 1,
                height: '100%',
                width: '100%',
              }}>

              <Box sx={{width: '100%'}}>

                <Typography variant="h6" align="left"  component="span"  dangerouslySetInnerHTML={{__html: product?.name}} sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                '& p': {margin: 0,padding: 0,},}}
                onClick={()=>{
                  setOpenProductDetails(product)
                }}/>

                <Typography variant="subtitle1" align="left"  component="span"  sx={{'& p': {margin: 0,padding: 0,}, transform: 'scale(1.2)'}}
                onClick={()=>{
                  setOpenProductDetails(product)
                }}
                >{product?.price}₽</Typography>


                <Typography variant="subtitle2" align="left"  component="span"  dangerouslySetInnerHTML={{__html: product?.description}} sx={{
                  mt: 1,
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  '& p': {margin: 0,padding: 0,},}}/>

              </Box>

            </Stack>

            { ( catalogShopProductEdit && userData?.shop?.id === product?.shop?.id) ?

              <Button
              sx={{mt: 1, height: 36, width: '100%', color: 'rgb(98, 100, 101)', fontSize: 14, border: '1px solid #e0e0e0', borderRadius: 3 }}
              onClick={() => {

                console.log(`product`)
                console.log(product)
                console.log(category[category?.length - 1])

                setSelectCategoryForAddProduct(category[category?.length - 1])

                setProductEdit(product)
                setPage('addProduct');
              }}>
                
                Изменить

              </Button>

              :

              <Button
              sx={{mt: 1, height: 36, width: '100%', color: 'rgb(98, 100, 101)', fontSize: 14, border: '1px solid #e0e0e0', borderRadius: 3 }}
              onClick={handleCheckedBasket() ? handleRemoveBasket : handleAddBasket}>

                { handleCheckedBasket() ? "Удалить" : "В корзину"}
              </Button>
            }

        </Box>

      </Grid>
  )
};

interface ChildProps {
  child: any;
};

const ChildItem: FC<ChildProps> = ({ child }) => {

  const [ category,setCategory] = useRecoilState<any>(categoryCatalogStateAtom);

  const [open, setOpen] = useState(false);

  const handleClick = () => {

    setOpen(!open);

    if(child?.childrens.length === 0) {

      if(category?.length === 1){

        setCategory([...category, child]);
      }else

      if(category?.length > 1){

        setCategory([...category.pop(), child]);
      }
    }
  };

  return (
    <>
      <ListItem button onClick={handleClick} sx={{ pl: 0, pr: 0 }}>
        <ListItemText primary={child?.name} />
        {child?.childrens?.length > 0 ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItem>
      {child?.childrens?.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding  sx={{ pl: 4 }}>
            {child?.childrens.map((child:any) => (
              <ChildItem key={child?.id} child={child} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

interface ParentProps {
  parent: any;
}

const ParentItem: FC<ParentProps> = ({ parent }) => {

  const [open, setOpen] = useState(false);

  const [ category,setCategory] = useRecoilState<any>(categoryCatalogStateAtom);

  const handleClick = () => {

    setOpen(!open);

    if(parent?.childrens.length === 0) return setCategory([...category, parent]);
  };

  return (
    <Box sx={{border: '1px solid #e0e0e0', borderRadius: 3, m: 1, mt: 2}}>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={parent.name} />
        {parent?.childrens.length > 0 ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItem>
      {parent?.childrens.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 4 }}>
            {parent?.childrens.map((child:any) => (
              <ChildItem key={child.id} child={child} />
            ))}
          </List>
        </Collapse>
      )}
    </Box>
  );
};

interface ListProps {
  data: any[];
};

const HierarchicalList: FC<ListProps> = ({ data }) => {

  const category = useRecoilValue<any>(categoryCatalogStateAtom);

  return (
    <List
    sx={{ 
      width: '100%',  
      bgcolor: 'background.paper',
      overflow: 'hidden',
      mt: 0,

      scrollbarWidth: "none",
      '&::-webkit-scrollbar': {display: 'none',},
      '&-ms-overflow-style:': {display: 'none',},
    }}
    component="nav">

      {category[0]?.childrens?.map((parent:any) => (
        <ParentItem key={parent.id} parent={parent} />
      ))}
    
    </List>
  );
};

const Catalog: FC<any> = () => {

  const {windowSize} = useMeasure();

  const [plugins, setPlugins] = useState<any>([]);

  useEffect(() => {
      setPlugins( [ new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false, }) ]);
  }, []);
  
  const width = 360 > (windowSize.width - 60) ? ( windowSize.width - 60 ) : 360 //360;


  const height = 540;

  const flickingRef = useRef<Flicking>(null);

  const categories = useRecoilValue<any>(categoriesStateAtom);

  const [category, setCategory] = useRecoilState<any>(categoryCatalogStateAtom);

  const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
  const [products, setProducts] = useState<any>([]);

  const [openProductDetails, setOpenProductDetails] = useRecoilState<any>(productStateAtom);

  const [productSort, setProductSort] = useRecoilState<any>(productSortStateAtom);

  //const [loading, setLoading] = useState<boolean>(false);

  
  const userData = useRecoilValue<any>(userDataStateAtom);
  const catalogShopProductEdit = useRecoilValue<boolean>(catalogShopProductEditStateAtom);
  
  const setSelectCategoryForAddProduct = useSetRecoilState<any>(selectCategoryForAddProductStateAtom);
  const setProductEdit = useSetRecoilState<any>(productEditStateAtom);
  const setPage = useSetRecoilState<any>(pageStateAtom);
  

  useEffect(() => {
    console.log(`catalogShopProductEdit 22`)
    console.log(catalogShopProductEdit)
  }, [catalogShopProductEdit])

  const [basket, setBasket] = useRecoilState<any[]>(basketStateAtom)

  const handleCheckedBasket = (product:any) => {

    for(const item of basket) {

      if(item?.id === product?.id) return true;
    }
    return false
  };
  
  const handleAddBasket = (product:any) => {

    setBasket([...basket, product]);
  };

  const handleRemoveBasket = (product:any) => {

    setBasket(basket.filter((item) => item?.id !== product?.id));
  };

  const searchFormTextFieldRef = useRef<any>(null);

  const [search, setSearch] = useState('');

  const { handleSubmit, control, getValues, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: '',
    }
  });

  useEffect(() => {

    if(search?.length < 2) return;

    setLoadingProducts(true);

    const query = qs.stringify({
      randomSort: false,
      filters: {

        ...(catalogShopProductEdit && {
          shop: {
            id: userData?.shop?.id
          }
        }),
        
        category: {
          id: category?.id
        },
        ...(search !== null && search?.length > 0 && {
          '$or': [
            {
              name: {
                $containsi: search
              }
            },
            {
              description: {
                $containsi: search
              }
            },
            {
              sku: {
                $containsi: search
              }
            },
          ],
        }),
      },
      populate: {
        shop: {
          fields: ["name", "description", "coords", "address"],
          populate: {
            icon: {
              fields: ["url"],
            }
          }
        },
        files: {
          populate: '*'
        },
        video: {
          fields: ['url']
        },
        photo: {
          fields: ['url']
        },
        photos: {
          fields: ['url']
        },
      },
      fields: ['name', 'description', 'price', 'stock', 'sku'],
      locale: ['ru'],
    }, {
      encodeValuesOnly: true, // prettify URL
    })

    api({ collection: 'products', query }).then((data) => {
      
      setLoadingProducts(false);

      if(data?.data) setProducts(data?.data);

    }).catch((error:any) => {
        
      setLoadingProducts(false);

      console.log(error);
    })
  
  }, [search]);

  useEffect(() => {

    if(category[category?.length - 1]?.childrens?.length === 0){

      setLoadingProducts(true);

      const query = qs.stringify({
        randomSort: false,
        filters: {
          shop: {
            show_on_map: true,
            show_on_map_before: {
              $gte: new Date().getTime()
            },
          },

          ...(catalogShopProductEdit && {
            shop: {
              id: userData?.shop?.id
            }
          }),

          category: {
            id: category[category?.length - 1]?.id
          }
        },
        populate: {
          shop: {
            fields: ["name", "description", "coords", "address"],
            populate: {
              icon: {
                fields: ["url"],
              }
            }
          },
          files: {
            populate: '*'
          },
          video: {
            fields: ['url']
          },
          photo: {
            fields: ['url']
          },
          photos: {
            fields: ['url']
          },
        },
        fields: ['name', 'description', 'price', 'stock', 'sku'],
        locale: ['ru'],
        sort: [productSort.value.sort],
      }, {
        encodeValuesOnly: true,
      })

      api({ collection: 'products', query }).then((data:any) => {

        if(data?.data) setProducts(data?.data);

        setLoadingProducts(false);
  
      }).catch((error:any) => {
        
        setLoadingProducts(false);

        console.log(error);
      })
    }
  }, [category, productSort]);



  const handleBack = () => {

    if(category.length === 1) return setCategory([]);
    if(category.length > 1) {
        
      const newB = [...category].shift(); 

      const arr = [];
      arr.push(newB);

      return setCategory(arr);
    };

    return setCategory([]);
  };

  const header = (index=0) => {
    return (
      <>
        <Stack
        direction="row"
        justifyContent={ category.length === 0 ? "flex-start" : "space-between"}
        alignItems="center"
        spacing={2}
        sx={{
          m: 1,
          height: 36,
          ml: 0,
          width: '100%',
        }}>

          <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          >

            { category?.length > 0 && <ArrowBackIosNewIcon sx={{cursor: 'pointer'}} onClick={handleBack} /> }
            
          </Box>

          <Box>
              
            { category.length === 0 ? 

              <Typography variant="h4" align="left"  dangerouslySetInnerHTML={{__html: 'Каталаг'}} sx={{color: 'rgb(98, 100, 101)', ml: -2, }}/>
            :
              <Typography variant="h6" align="left"  dangerouslySetInnerHTML={{__html: category[index]?.name}} sx={{
                color: 'rgb(98, 100, 101)',
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                '& p': {margin: 0,padding: 0,},
              }}/>
            }
          </Box>

          { category?.length > 0 && <TuneIcon sx={{ cursor: 'pointer', width: 48 }} onClick={() => { setProductSort((prevState:any) => ({ ...prevState, open: true }) )}}/> }
          
        </Stack>
      </>
    )
  }

  useEffect(() => {

    console.log(`category`)
    console.log(category)
  }, [category])

  return (
    <>
      <Container  sx={{ pt:/* 1 */ 'calc(10px + env(safe-area-inset-top))' , pb:/*  5 */ 'calc(80px + env(safe-area-inset-top))', pl: /*windowSize.width < 375 ? 1 :*/ 3, pr: /*windowSize.width < 375 ? 1 :*/ 3, overflow: 'hidden', }}>

        <Controller
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { isTouched, isDirty, error },
          }) => (
            <TextField
              fullWidth
              size="small"
              margin="normal"
              variant="outlined"
              //label="Поиск магазина"
              onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
              autoFocus={false}
              placeholder="Поиск по товарам"
              value={search}
              onBlur={onBlur}
              inputRef={(input) => {
                ref(input);
                if (input) searchFormTextFieldRef.current = input;
              }}
              onChange={(e:any)=>{

                setSearch(e.target.value);
              }}
              onFocus={(e:any)=>{

              }}
              InputProps={{
                endAdornment: search !== '' && (
                  <InputAdornment position="end" sx={{ marginRight: 1 }}>
                    <IconButton onClick={()=>{ setSearch(''); }} edge="end">
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          name="name"
          control={control}
          rules={{ required: true }}
        />
      
        { search.length === 0 && <Typography variant="h4" align="left"  dangerouslySetInnerHTML={{__html: 'Каталаг'}} sx={{color: 'rgb(98, 100, 101)', mt:1 }}/>}

        <>

          { search.length === 0 &&
            <>

              {categories?.map((item:any, index:number) => {

                return (
                  <Box key={index} 
                  sx={{
                    mt: 2,  height: 160, cursor: 'pointer', border: '1px solid #ccc', borderRadius: 2.5, 
                    background: `${item?.background_color};`, color: 'white', fontSize: 28,
                  }} 
                  onClick={() => { setCategory([item]) }}>

                  <Box
                  sx={{
                    p: 2,
                    height: 160,
                    backgroundImage: `url(${item?.photo?.url})`,
                    backgroundPosition: 'right 10px bottom 2px',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}>

                    {item?.name}

                    </Box>
                  </Box>
                )
              })}
            </>
          }

        </>

        <Box 
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          overflow: 'hidden',
          width: '100%',
        }}>

          { search.length > 0 && products.length === 0 ? 
            
            <Box 
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              overflow: 'hidden',
              width: '100%',
              height: "80vh",

            }}>

              <ProductionQuantityLimitsIcon fontSize='large' sx={{transform: 'scale(3)'}}/>

              <Typography variant="h6" align="center"  component="span" sx={{mt: 5, }}>

                Ничего не найдено!

              </Typography>

            </Box>

          :
            <>

              { ( search.length > 0 && products.length > 0 )  && 
                
                <Grid container spacing={1} sx={{
                  userSelect: 'none',
                  overflow: 'auto',
                  height: 'calc(100vh - 120px)',
                  width: '100%',
                  scrollbarWidth: "none",
                  '&::-webkit-scrollbar': { display: 'none', },
                  '&-ms-overflow-style:': { display: 'none', },
                }}>
                  
                  { products?.map((product:any) => (<ProductItem key={product?.id} product={product} />)) }
              
                </Grid>

              }

            </>
          }

        </Box>

      </Container>

      <Draggable open={( ( category && category.length > 0 ) ) || false} setOpen={handleBack}>
        
        <Box
        sx={{
          p: 3,
          //pt: 1,
          pt: 'calc(10px + env(safe-area-inset-top))',
          pb: 1,
          userSelect: 'none',
          overflow: 'hidden',
          height: 'calc(100vh - 60px)',
          width: '100%',
          scrollbarWidth: "none",
          position: 'absolute',
          '&::-webkit-scrollbar': { display: 'none', },
          '&-ms-overflow-style:': { display: 'none', },
        }}>
      
          {header(0)}
              
          <Box
          sx={{
            userSelect: 'none',
            overflow: 'auto',
            height: 'calc(100vh - 120px)',
            width: '100%',
            scrollbarWidth: "none",
            '&::-webkit-scrollbar': { display: 'none', },
            '&-ms-overflow-style:': { display: 'none', },
          }}>

            <HierarchicalList data={categories}/>
        
          </Box>
      
        </Box>
      
      </Draggable>

      <Draggable open={( category && category[category.length - 1]?.childrens?.length === 0  ) || false} setOpen={handleBack}>
        
        <Box
        sx={{
          p: 3,
          //pt: 1,
          pt: 'calc(10px + env(safe-area-inset-top))',
          pb: 1,
          userSelect: 'none',
          overflow: 'hidden',
          height: 'calc(100vh - 60px)',
          width: '100%',
          scrollbarWidth: "none",
          position: 'absolute',
          '&::-webkit-scrollbar': { display: 'none', },
          '&-ms-overflow-style:': { display: 'none', },
        }}>
      
          {header(category.length - 1)}

          {/* <Box
          sx={{
            userSelect: 'none',
            overflow: 'auto',
            height: 'calc(100vh - 120px)',
            width: '100%',
            scrollbarWidth: "none",
            '&::-webkit-scrollbar': { display: 'none', },
            '&-ms-overflow-style:': { display: 'none', },
          }}>
             */}

          <Container /* maxWidth="sm" */ sx={{ m: 0, p:0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start',  }}>

            { loadingProducts ? 

            <Box sx={{ height: 'calc(100vh - 120px)', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
              <CircularProgress color="secondary" /> 
            </Box>
            :
            <>
              { products.length === 0 && 
              
              <Box 
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                overflow: 'hidden',
                width: '100%',
                height: "80vh",
              }}>
        
                <ShoppingCartIcon fontSize='large' sx={{transform: 'scale(3)'}}/>
        
                <Typography variant="h6" align="left"  component="span" sx={{mt: 5}}>
        
                  ОЙ! А тут пусто...
        
                </Typography>
        
              </Box>

            }
        
            <Grid container spacing={1} sx={{
              userSelect: 'none',
              overflow: 'auto',
              height: 'calc(100vh - 120px)',
              width: '100%',
              scrollbarWidth: "none",
              '&::-webkit-scrollbar': { display: 'none', },
              '&-ms-overflow-style:': { display: 'none', },
            }}>

              { products?.map((product:any) => (<ProductItem key={product?.id} product={product} />)) }
              
            </Grid>
          </> }

          </Container>
          
        </Box>
      
      </Draggable>

      <Draggable open={size(openProductDetails)>0||false} setOpen={()=>{setOpenProductDetails(null);}}>
        
        <Box 
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        sx={{
          position: 'absolute',
          overflow: 'hidden',
          width: '100%',
          mt: 0,
          //pt: 2.5,
          pt: 'calc(25px + env(safe-area-inset-top))',
          top: 0,
        }}>
        
          <Grid container sx={{ width: '100%' }}>
            
            <Grid xs={2} >

              <ArrowBackIosNewIcon sx={{ cursor: 'pointer', width: 48, ml: 2 }} onClick={() => { setOpenProductDetails(null); }}/>

            </Grid>

            <Grid xs={9}>
        
              <Typography variant="h6" align="left"   component="span"  dangerouslySetInnerHTML={{__html: openProductDetails?.name}} sx={{
              mt: -0.5,
              ml: 0,
              width: '100%',
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
              }}/>
        
            </Grid>

          </Grid>
        
          <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            mt: 2,
            p: 3,
            pt: 1,
            pb: 1,
            userSelect: 'none',
            overflow: 'auto',
            height: "calc(100vh - 120px)",
            width: '100vw',
            scrollbarWidth: "none",
            '&::-webkit-scrollbar': { display: 'none', },
            '&-ms-overflow-style:': { display: 'none', },
          }}>

            <Box 
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
              userSelect: 'none',
              overflow: 'auto',
              width: '100%',
              height, minHeight: height,
              borderRadius: 3,
            }}>
        
              <Box sx={{ borderRadius: 3, overflowX: "hidden", width, height, minHeight: height }}>

                <Flicking ref={flickingRef} /* plugins={plugins} */ circular={ ( openProductDetails?.photos?.length === 1 && !openProductDetails?.video ) ? false : true}>

                  {/* {openProductDetails?.video &&
                    <Box
                      className="card-panel"
                      sx={{
                        width,
                        height,
                      }}>

                      <CardMedia
                          component='video'
                          image={openProductDetails?.video.url}
                          src={openProductDetails?.video.url}
                          autoPlay
                          loop
                          muted
                      />
                    </Box>
                  }

                  {openProductDetails?.photos?.map((item:any, index:number) => (
                    <Box
                      key={index}
                      className="card-panel"
                      sx={{
                        backgroundImage:`url(${item?.url})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        width,
                        height,
                      }}/>
                  ))} */}

                  {openProductDetails?.files?.map((item:any, index:number) => {

                    if(item?.mime.includes("image")) {
                      return (
                        <Box
                          key={index}
                          className="card-panel"
                          sx={{
                            backgroundImage:`url(${item?.url})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            width,
                            height,
                          }}/>
                      )
                    }

                    if(item?.mime.includes("video")) {
                      return (
                        <Box
                        className="card-panel"
                        sx={{
                          width,
                          height,
                        }}>
  
                        <CardMedia
                            component='video'
                            image={item?.url}
                            src={item?.url}
                            autoPlay
                            loop
                            muted
                        />
                      </Box>
                      )
                    }
                    
                    return null;

                  })}

                </Flicking>
              </Box>
            </Box>
        
            <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={1}
            sx={{
              m: 1,
              mt: 2,
              width: 'calc(100% - 16px)',
            }}>
      
              <Box sx={{width: '100%'}}>
                
                <Typography variant="h6" align="left"  component="span"  sx={{ /* pl: 2, */'& p': {margin: 0,padding: 0,}, transform: 'scale(1.2)'}}>{openProductDetails?.price}₽</Typography>
      
                <Box>
      
                  <Typography variant="caption" align="left"  component="span"  sx={{'& p': {margin: 0,padding: 0,},}}>Арт</Typography>
                  <Typography variant="caption" align="left"  component="span"  dangerouslySetInnerHTML={{__html: openProductDetails?.sku}} sx={{ pl: 1, '& p': {margin: 0,padding: 0},}}/>
      
                </Box>
      
                <Typography variant="subtitle1" align="left"  component="span"  dangerouslySetInnerHTML={{__html: `Магазин: ${openProductDetails?.shop?.name}`}} sx={{
                  mt: 1,
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  '& p': {margin: 0,padding: 0,},}}/>

                <Typography variant="subtitle2" align="left"  component="span"  dangerouslySetInnerHTML={{__html: openProductDetails?.description}} sx={{
                  mt: 1,
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 15,
                  '& p': {margin: 0,padding: 0,},}}/>

                {/* <Button sx={{mt: 1, height: 36, width: '100%', color: 'rgb(98, 100, 101)', fontSize: 14, border: '1px solid #e0e0e0', borderRadius: 3 }} onClick={()=>{
                  handleCheckedBasket(openProductDetails) ? handleRemoveBasket(openProductDetails) : handleAddBasket(openProductDetails)
                }}>
                  { handleCheckedBasket(openProductDetails) ? "Удалить" : "В корзину"}
                </Button> */}

                { ( catalogShopProductEdit && userData?.shop?.id === openProductDetails?.shop?.id) ?

                  <Button
                  sx={{mt: 1, height: 36, width: '100%', color: 'rgb(98, 100, 101)', fontSize: 14, border: '1px solid #e0e0e0', borderRadius: 3 }}
                  onClick={() => {

                    console.log(`openProductDetails`)
                    console.log(openProductDetails)

                    setSelectCategoryForAddProduct(category[category?.length - 1])

                    setProductEdit(openProductDetails)
                    setPage('addProduct');
                  }}>
                    
                    Изменить

                  </Button>
                :
                  <Button
                  sx={{mt: 1, height: 36, width: '100%', color: 'rgb(98, 100, 101)', fontSize: 14, border: '1px solid #e0e0e0', borderRadius: 3 }}
                  onClick={()=>{ handleCheckedBasket(openProductDetails) ? handleRemoveBasket(openProductDetails) : handleAddBasket(openProductDetails)}}>

                    { handleCheckedBasket(openProductDetails) ? "Удалить" : "В корзину"}
                  </Button>
                }

              </Box>
      
            </Stack>

          </Box>
        
        </Box>
        
      </Draggable>
                                  
    </>
  )
};

export default Catalog;
